import React, { useEffect, useState } from 'react';
import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api';

const libraries = ['places'];
const initialContainerStyle = {
  width: '40vw',
  height: '60vh',
};
const center = {
  lat: -3.931171, // default latitude
  lng: -38.380994, // default longitude
};

const Map = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyACLXWhQEN8r-NHI-3nPUQ4BbX_G6bQo88',
    libraries,
  });
  const [mapContainerStyle, setMapContainerStyle] = useState(initialContainerStyle)

  useEffect(() => {
    if(window.innerWidth <= 610){
      setMapContainerStyle({
        width: '60vw',
        height: '60vh',
      })
    }
  }, [window.innerWidth])

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={10}
        center={center}
      >
        <MarkerF position={center} />
      </GoogleMap>
    </div>
  );
};

export default Map;