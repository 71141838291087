import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Save from "../services/confirmPresenceService";

function ConfirmPresence() {
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async () => {
    setIsLoading(true);
    await Save(formData);
    setIsSubmitted(true);
    setIsLoading(false);
  };

  return (
    <Form className="teste">
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Nome Completo</Form.Label>
        <Form.Control
          type="input"
          placeholder="Insira seu nome completo"
          name="nome"
          disabled={isSubmitted}
          onChange={(e) => handleFormChange(e)}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>E-mail</Form.Label>
        <Form.Control
          type="email"
          placeholder="exemplo@email.com"
          name="email"
          disabled={isSubmitted}
          onChange={handleFormChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Telefone para contato</Form.Label>
        <Form.Control
          mask="(00) 00000-0000"
          placeholder="(85) 99999-9999"
          name="contato"
          disabled={isSubmitted}
          onChange={handleFormChange}
        />
      </Form.Group>
      {isSubmitted ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button variant="success" className="form-submit-btn" disabled>
            Presença Confirmada
          </Button>
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="outline-primary"
            className="form-submit-btn"
            disabled={isLoading}
            onClick={handleFormSubmit}
          >
            Confirmar Presença
          </Button>
        </div>
      )}
    </Form>
  );
}

export default ConfirmPresence;
