import React, { useEffect, useState } from 'react'
import {initMercadoPago, Payment} from '@mercadopago/sdk-react'
import PaymentStatus from './PaymentStatus';
import { FaXmark } from "react-icons/fa6";

export default function PaymentSection({ value, handleClose }) {
  const [paymentApproved, setPaymentApproved] = useState(false);
  const [paymentId, setPaymentId] = useState();

  useEffect(() => {
    initMercadoPago('APP_USR-60c34e1b-ccec-413f-b9f6-ffcee7e7dacd', { locale: 'pt-BR' });
  }, [])

  const initialization = {
    amount: value,
    preferenceId: "<PREFERENCE_ID>",
   };
  const customization = {
    paymentMethods: {
      // ticket: "all",
      bankTransfer: "all",
      creditCard: "all",
      debitCard: "all",
      // mercadoPago: "all",
    },
   };
  const onSubmit = async (
    { selectedPaymentMethod, formData }
   ) => {
    // callback chamado ao clicar no botão de submissão dos dados
    return new Promise((resolve, reject) => {
      fetch("https://site-casamento-backend.azurewebsites.net/process_payment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((response) => {
          setPaymentId(response.id);
          setPaymentApproved(true);
          resolve();
        })
        .catch((error) => {
          // lidar com a resposta de erro ao tentar criar o pagamento
          reject();
        });
    });
   };
   const onError = async (error) => {
    // callback chamado para todos os casos de erro do Brick
    console.log(error);
   };
   const onReady = async () => {
    /*
      Callback chamado quando o Brick estiver pronto.
    */
   };

  const renderPaymentCard = () => {
    return (
      <div>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <FaXmark
          className='Xmark' 
          style={{marginRight: "10px"}}
          onClick={handleClose}
          >
          </FaXmark>
        </div>
        <Payment
          initialization={initialization}
          customization={customization}
          onSubmit={onSubmit}
          onReady={onReady}
          onError={onError}
          />
      </div>)
    }

  const renderPaymentStatus = (id) => {
    return (<PaymentStatus id={id}/>)
  } 

  return (
    <div>
      { paymentApproved ? renderPaymentStatus(paymentId) : renderPaymentCard()}
    </div>
  )
}