import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

function GiftItem({image, title, price, onBuyClick}) {

  const onButtonClick = () =>
  {
    onBuyClick(price);
  }

  return (
    <Card id='GiftItem' className='shadow p-3 mb-5 bg-white rounded' >
      <Card.Img src={image} id='GiftImg'/>
      <Card.Body className='d-flex flex-column align-items-center justify-content-around'>
          <Card.Text  style={{textAlign: "center"}}>
            {title}
          </Card.Text>
          <Card.Text>
            R$ {price}
          </Card.Text>
          {/* <footer> */}
            <Button id='GiftBtn' variant="primary" className='ml-auto' onClick={onButtonClick}>Presentear</Button>
          {/* </footer> */}
      </Card.Body>
    </Card>
  );
}

export default GiftItem;