import React from 'react'
import { StatusScreen } from '@mercadopago/sdk-react';
export default function PaymentStatus({id}) {
    const initialization = {
        paymentId: id, // id do pagamento a ser mostrado
    };
    const onError = async (error) => {
        // callback chamado para todos os casos de erro do Brick
        console.log(error);
    };
    const onReady = async () => {
        /*
        Callback chamado quando o Brick estiver pronto.
        Aqui você pode ocultar loadings do seu site, por exemplo.
        */
    };
   
    return (
        <div>
            <StatusScreen
            initialization={initialization}
            onReady={onReady}
            onError={onError}
            />
        </div>
    )
}
