import React from 'react'
import monogram from '../images/teste.svg';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Link} from 'react-scroll'
export default function Header() {
  return (
    <header className="header">
        <div className='monogram-container'>
            <img src={monogram} className="monogram" alt="monogram" />
        </div>
        <div className='link-container'>
            <Nav.Link>
                <Link 
                    activeClass="active"
                    smooth spy to = "LOCAL"
                    duration={200}
                    offset={-70}
                >
                    <span>LOCAL</span>
                </Link>
            </Nav.Link>
            <Nav.Link>
                <Link activeClass="active"
                    smooth spy to = "LISTADEPRESENTES"
                    duration={200}
                    offset={-70}
                    >
                    <span>LISTA DE PRESENTES</span>
                </Link>
            </Nav.Link>
            <Nav.Link>
                <Link activeClass="active"
                    smooth spy to = "CONFIRMARPRESENCA"
                    duration={200}
                    offset={-70}
                    >
                        <span>CONFIRMAR PRESENÇA</span>
                </Link>
            </Nav.Link>
        </div>
    </header>
  )
}
