import React, {useState} from 'react'
import GiftsData from '../resources/Gifts';
import GiftItem from './GiftItem';
import PaymentSection from './PaymentSection';
import Modal from 'react-bootstrap/Modal'
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Row, Col } from 'react-bootstrap';
export default function GiftsSection() {
  const [show, setShow] = useState(false);
  const [paymentValue, setPaymentValue] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = (value) => 
  {
    setPaymentValue(value);
    setShow(true)
  };

  return (
    <Row xs={2} md={3} className="g-4">
          {GiftsData.map((g) =>
          <Col className='col-lg-3 d-flex'>
            <GiftItem onBuyClick={handleShow} image={g.img} title={g.cotent} price={g.value}/>
          </Col> 
          )}
            <Modal show={show} onHide={handleClose}>
              <Modal.Body>
                  <PaymentSection value={paymentValue} handleClose={handleClose}/>
              </Modal.Body>
            </Modal>
    </Row>
  )
}
