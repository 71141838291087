import React, { useState, useEffect } from 'react';
import { Howl, Howler } from 'howler';
import songs from '../resources/songs/songs';
import { FaPlay, FaPause, FaMusic } from "react-icons/fa6";
const Music = () => {
  const [playlist, setPlaylist] = useState([]);
  const [currentSong, setCurrentSong] = useState(0);
  const [sound, setSound] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

   useEffect(() => {
    setPlaylist(songs);
   }, []);

   useEffect(() => {
    if(sound){
      playSong();
    }
    }, [currentSong]);

  const playSong = () => {
    if(sound && !isPlaying){
      sound.play();
      setIsPlaying(true);
      return;
    }
    const newSound = new Howl({
      src: [playlist[currentSong]],
      volume: 0.2,
      onend: () => {
          setCurrentSong((prevSong) => (prevSong + 1) % playlist.length);
      }
    });
    setSound(newSound);
    newSound.play();
    setIsPlaying(true);
  };

  const pauseSong = () => {
    if (sound) {
        sound.pause();
    }
    setIsPlaying(false);
  };

  return (
    <div className='music-player-container'>
      <FaMusic />
      {isPlaying ? 
        <FaPause style={{margin: "0 0 0 7"}} onClick={pauseSong}/> : 
        <FaPlay style={{margin: "0 0 0 7"}} onClick={playSong} />}
    </div>
  );
};

export default Music;
