import Noiva from "../images/noiva_jogando_buque.jpg";
import Batedeira from "../images/batedeira.jpg";
import Cobertor from "../images/cobertor-razao.png";
import NaoTeDeiNada from "../images/nao-dei-nada.png"
import Deus from "../images/deus.jpg"
import FotoAlexandre from '../images/foto-alexandre.png'
import Juubi from '../images/juubi.png'
import March from '../images/march-tunado.jpeg'
import Mimir from '../images/mimir.jpg'
import LavaLoucas from '../images/lava-louças.jpg'
import Boleto from '../images/boleto.jpg'
import Atraso from '../images/atraso.png'

export default 
[
    {
        "cotent": "Taxa pra Noiva jogar o buquê na sua direção",
        "img": Noiva,
        "value": 350.00
    },
    {
        "cotent": "Batedeira planetária de última geração",
        "img": Batedeira,
        "value": 538.00
    },
    {
        "cotent": "Cobertor para a Noiva estar sempre coberta de razão",
        "img": Cobertor,
        "value": 210.00
    },
    {
        "cotent": "Para não dizer que não dei nada",
        "img": NaoTeDeiNada,
        "value": 98.00
    },
    {
        "cotent": "A intervenção divina tocou seu coração",
        "img": Deus,
        "value": 1100.00
    },
    {
        "cotent": "Para a noiva não atrasar a cerimônia",
        "img": Atraso,
        "value": 186.00
    },
    {
        "cotent": "Obrigar o Noivo a tirar foto",
        "img": FotoAlexandre,
        "value": 154.00
    },
    {
        "cotent": "1 ano de sachê para a Jubi",
        "img": Juubi,
        "value": 397.00
    },
    {
        "cotent": "Adote um boleto atrasado",
        "img": Boleto,
        "value": 229.00
    },
    {
        "cotent": "Manutenção do carro para a Lua de Mel",
        "img": March,
        "value": 970.00
    },
    {
        "cotent": "Diária do hotel para os noivos passarem mais tempo descansando",
        "img": Mimir,
        "value": 454.00
    },
    {
        "cotent": "Lava louças para evitar brigas entre o casal",
        "img": LavaLoucas,
        "value": 2599.00
    },
    // {
    //     "cotent": "Teste",
    //     "img": Noiva,
    //     "value": 20.00
    // }
]